@charset "UTF-8";
@import "../mixin";

.taxonomy-ling_cat2_content {
  padding: 0 0 120px;

  @include sp {
    padding: 0 0 80px;
  }

  &--ttl {
    font-size: 32px;
    font-family: $ff-noto_serif;
    font-weight: normal;
    color: #fff;
    max-width: 100%;
    height: 325px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 80px;
    background: pink; //ダミー。画像がなくてもテキストが見えるようにするだけ。

    // メイン画像のバリエーション modifierで切り替えます
    // リング,ベーシックプラン
    &-ring,
    &-basicplan {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-ring.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }

    // 婚約指輪, カジュアル, ゴージャス, シンプル, モチーフ, キュート
    &-engagement,
    &-casual,
    &-gorgeous,
    &-simple2,
    &-motif,
    &-cute {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-engagement.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }

    // 結婚指輪,
    &-marriage,
    &-combi,
    &-gold,
    &-eternity-marriage,
    &-twist,
    &-simple,
    &-narrow,
    &-thick,
    &-individuality,
    &-frosted,
    &-semi-order {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-marriage.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }

    // エタニティリング
    &-eternity {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-eternity.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }

    // ネックレス,地金ネックレス
    &-necklaces,
    &-bullion {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-necklaces.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }
    // 婚約ネックレス
    &-necklace {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-necklace.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }
    // パールネックレス
    &-pearl-necklace {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-perl_necklace.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }

    // ルース,ダイヤモンド,パール,カラーストーン,アンティークジュエリー
    &-loose,
    //&-diamond,
    //&-pearl,
    //&-colorstone,
    &-antique {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-loose.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }

    // ベビーリング
    &-babyring {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-babyring.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }

    // アニバーサリー（誕生石,ルビー）
    //&-ruby,
    &-anniversary,
    &-birthstone
    {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-anniversary.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }

    &-diamond,&-pearl,&-ruby,&-colorstone {
      color: #333;
      background: url('/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-colorstone.png') no-repeat;
      background-size: cover;
      background-position: center center;
    }

    @include sp {
      font-size: 20px;
      height: 128px;
      margin: 0 0 40px;
    }
  }

  &--inner {
    width: 1000px;
    margin: 0 auto;

    @include sp {
      width: calc(100% - 48px);
    }
  }

  &--wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 0 120px;

    @include sp {
      margin: 0 0 80px;
    }
  }

  &--link {
    display: block;
    width: 320px;
    color: #333;
    text-decoration: none;
    margin: 32px 0 0;
    transition: 0.3s;

    &:nth-child(-n+3) {
      margin: 0;
    }

    &:hover {
      opacity: 0.8;
    }

    @include sp {
      width: calc(50% - 8px);

      &:nth-child(-n+3) {
        margin: 32px 0 0;
      }

      &:nth-child(-n+2) {
        margin: 0;
      }
    }
  }

  &--img {
    display: block;
    margin: 0 0 4px;
  }

  &--text {
    font-size: 15px;
  }
}

.taxonomy-ling_cat2_tab {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 4em;
  width: fit-content;
  row-gap: 1em;
  flex-wrap: wrap;
  margin: 0 auto 80px;

  @include sp {
    width: auto;
    grid-template-columns: repeat(3, 1fr);
    max-width: calc(100% - 48px);
    gap: 20px;
  }

  &--type {
    font-size: 15px;
    display: block;
    text-align: center;
    transition: 0.3s;
    color: #333333;
    text-decoration: none;
    padding-bottom: 8px;

    &:hover {
      opacity: 0.8;
    }

    @include sp {}

    &-line {
      border-bottom: 2px solid #782221;
    }
  }
}

.taxonomy-ling_cat2_lineup {
  width: 1000px;
  margin: 0 auto 64px;

  @include sp {
    width: calc(100% - 32px);
    margin: 0 auto 48px;
  }

  &--ttl {
    font-size: 24px;
    font-family: $ff-noto_serif;
    font-weight: normal;
    text-align: center;
    margin: 0 0 24px;

    @include sp {
      font-size: 18px;
    }
  }

  &--wrap {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 1000px;
    margin: 0 auto;

    @include sp {
      justify-content: space-between;
      width: 100%;
    }
  }

  &--link {
    width: 220px;
    display: block;
    color: #333;
    text-decoration: none;
    transition: 0.3s;
    margin: 56px 0 0 40px;
    position: relative;
    padding-bottom: 20px;

    &:nth-child(4n+1) {
      margin: 56px 0 0 0;

      &:first-child {
        margin: 0;
      }
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin: 0 0 0 40px;
    }

    &:hover {
      opacity: 0.8;
    }

    @include sp {
      width: calc(50% - 8px);
      margin: 40px 0 0;

      &:nth-child(-n+2) {
        margin: 0;
      }

      &:nth-child(4n+1) {
        margin: 40px 0 0;
      }

      &:nth-child(3),
      &:nth-child(4) {
        margin: 40px 0 0;
      }
    }
  }

  &--img {
    display: block;
    max-width: 100%;
    width: 220px;
    height: 220px;
    object-fit: cover;
    margin: 0 0 10px;

    @include sp {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
    }
  }

  &--text {
    font-size: 14px;
    font-family: $ff-noto_serif;
    line-height: 1.5;
    text-align: center;
    margin: 0 0 12px;

    @include sp {
      font-size: 11px;
    }
  }

  &--price {
    font-size: 18px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 16px;

    @include sp {
      font-size: 14px;
    }
  }

  &--more {
    font-family: $ff-noto_serif;
    font-size: 12px;
    display: block;
    width: 120px;
    margin: 0 auto;
    padding-bottom: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 4px;
      height: 4px;
      border-top: 1px solid #333;
      border-right: 1px solid #333;
      transform: rotate(45deg);
      top: calc(50% - 8px);
      right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #000;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @include sp {
      font-size: 16px;
    }
  }
}

.taxonomy-ling_cat2_banners {
  background: #F3F3F3;
  padding: 80px 0;

  @include sp {
    padding: 40px 0;
  }

  &--inner {
    width: 984px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include sp {
      width: calc(100% - 56px);
    }
  }

  &--link {
    width: 480px;
    display: block;
    transition: 0.3s;

    &:nth-child(n+3) {
      margin: 24px 0 0;
    }

    &:hover {
      opacity: 0.8;
    }

    @include sp {
      width: 100%;

      &:nth-child(n+2) {
        margin: 12px 0 0;
      }
    }
  }
}

.taxonomy-ling_cat2_top_section {
  max-width: 720px;
  margin: 0 auto 80px auto;
  @include sp {
    padding: 0 20px;
    margin-bottom: 60px;
  }
  
  &--description {
    font-size: 15px;
    line-height: 1.5;
    text-align: center;    
  }

  &--nav {
    margin: 33px auto 0 auto;
    display: flex;
    justify-content: space-between;
    max-width: 680px;   
    @include sp {
      flex-direction: column;
    } 
    &-center {
      justify-content: center;
    }
  }

  &--link {
    border: 1px solid #707070;
    max-width: 320px;
    width: 100%;
    font-size: 15px;
    text-decoration: none;
    color: #333;
    text-align: center;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.15s;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 5px;
      height: 5px;
      border-right: 1px solid #333;
      border-bottom: 1px solid #333;
      transform: rotate(-45deg);
      right: 20px;
      top: calc(50% - 2.5px);
    }
    &:hover {
      background: #f1f1f1;
    }
    @include sp {
      width: 100%;
      &:last-child {
        margin-top: 12px;
      }
    }
  }
}