.taxonomy-ling_cat2_content {
  padding: 0 0 120px;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_content {
    padding: 0 0 80px;
  }
}

.taxonomy-ling_cat2_content--ttl {
  font-size: 32px;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  color: #fff;
  max-width: 100%;
  height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 80px;
  background: pink;
}

.taxonomy-ling_cat2_content--ttl-ring, .taxonomy-ling_cat2_content--ttl-basicplan {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-ring.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.taxonomy-ling_cat2_content--ttl-engagement, .taxonomy-ling_cat2_content--ttl-casual, .taxonomy-ling_cat2_content--ttl-gorgeous, .taxonomy-ling_cat2_content--ttl-simple2, .taxonomy-ling_cat2_content--ttl-motif, .taxonomy-ling_cat2_content--ttl-cute {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-engagement.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.taxonomy-ling_cat2_content--ttl-marriage, .taxonomy-ling_cat2_content--ttl-combi, .taxonomy-ling_cat2_content--ttl-gold, .taxonomy-ling_cat2_content--ttl-eternity-marriage, .taxonomy-ling_cat2_content--ttl-twist, .taxonomy-ling_cat2_content--ttl-simple, .taxonomy-ling_cat2_content--ttl-narrow, .taxonomy-ling_cat2_content--ttl-thick, .taxonomy-ling_cat2_content--ttl-individuality, .taxonomy-ling_cat2_content--ttl-frosted, .taxonomy-ling_cat2_content--ttl-semi-order {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-marriage.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.taxonomy-ling_cat2_content--ttl-eternity {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-eternity.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.taxonomy-ling_cat2_content--ttl-necklaces, .taxonomy-ling_cat2_content--ttl-bullion {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-necklaces.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.taxonomy-ling_cat2_content--ttl-necklace {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-necklace.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.taxonomy-ling_cat2_content--ttl-pearl-necklace {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-perl_necklace.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.taxonomy-ling_cat2_content--ttl-loose, .taxonomy-ling_cat2_content--ttl-antique {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-loose.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.taxonomy-ling_cat2_content--ttl-babyring {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-babyring.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.taxonomy-ling_cat2_content--ttl-anniversary, .taxonomy-ling_cat2_content--ttl-birthstone {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-anniversary.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

.taxonomy-ling_cat2_content--ttl-diamond, .taxonomy-ling_cat2_content--ttl-pearl, .taxonomy-ling_cat2_content--ttl-ruby, .taxonomy-ling_cat2_content--ttl-colorstone {
  color: #333;
  background: url("/images/taxonomy-ling_cat2/bg-taxonomy-ling_cat2_content--ttl-colorstone.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_content--ttl {
    font-size: 20px;
    height: 128px;
    margin: 0 0 40px;
  }
}

.taxonomy-ling_cat2_content--inner {
  width: 1000px;
  margin: 0 auto;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_content--inner {
    width: calc(100% - 48px);
  }
}

.taxonomy-ling_cat2_content--wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 0 120px;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_content--wrap {
    margin: 0 0 80px;
  }
}

.taxonomy-ling_cat2_content--link {
  display: block;
  width: 320px;
  color: #333;
  text-decoration: none;
  margin: 32px 0 0;
  transition: 0.3s;
}

.taxonomy-ling_cat2_content--link:nth-child(-n+3) {
  margin: 0;
}

.taxonomy-ling_cat2_content--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_content--link {
    width: calc(50% - 8px);
  }
  .taxonomy-ling_cat2_content--link:nth-child(-n+3) {
    margin: 32px 0 0;
  }
  .taxonomy-ling_cat2_content--link:nth-child(-n+2) {
    margin: 0;
  }
}

.taxonomy-ling_cat2_content--img {
  display: block;
  margin: 0 0 4px;
}

.taxonomy-ling_cat2_content--text {
  font-size: 15px;
}

.taxonomy-ling_cat2_tab {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 4em;
  width: fit-content;
  row-gap: 1em;
  flex-wrap: wrap;
  margin: 0 auto 80px;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_tab {
    width: auto;
    grid-template-columns: repeat(3, 1fr);
    max-width: calc(100% - 48px);
    gap: 20px;
  }
}

.taxonomy-ling_cat2_tab--type {
  font-size: 15px;
  display: block;
  text-align: center;
  transition: 0.3s;
  color: #333333;
  text-decoration: none;
  padding-bottom: 8px;
}

.taxonomy-ling_cat2_tab--type:hover {
  opacity: 0.8;
}

.taxonomy-ling_cat2_tab--type-line {
  border-bottom: 2px solid #782221;
}

.taxonomy-ling_cat2_lineup {
  width: 1000px;
  margin: 0 auto 64px;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_lineup {
    width: calc(100% - 32px);
    margin: 0 auto 48px;
  }
}

.taxonomy-ling_cat2_lineup--ttl {
  font-size: 24px;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  text-align: center;
  margin: 0 0 24px;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_lineup--ttl {
    font-size: 18px;
  }
}

.taxonomy-ling_cat2_lineup--wrap {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 1000px;
  margin: 0 auto;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_lineup--wrap {
    justify-content: space-between;
    width: 100%;
  }
}

.taxonomy-ling_cat2_lineup--link {
  width: 220px;
  display: block;
  color: #333;
  text-decoration: none;
  transition: 0.3s;
  margin: 56px 0 0 40px;
  position: relative;
  padding-bottom: 20px;
}

.taxonomy-ling_cat2_lineup--link:nth-child(4n+1) {
  margin: 56px 0 0 0;
}

.taxonomy-ling_cat2_lineup--link:nth-child(4n+1):first-child {
  margin: 0;
}

.taxonomy-ling_cat2_lineup--link:nth-child(2), .taxonomy-ling_cat2_lineup--link:nth-child(3), .taxonomy-ling_cat2_lineup--link:nth-child(4) {
  margin: 0 0 0 40px;
}

.taxonomy-ling_cat2_lineup--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_lineup--link {
    width: calc(50% - 8px);
    margin: 40px 0 0;
  }
  .taxonomy-ling_cat2_lineup--link:nth-child(-n+2) {
    margin: 0;
  }
  .taxonomy-ling_cat2_lineup--link:nth-child(4n+1) {
    margin: 40px 0 0;
  }
  .taxonomy-ling_cat2_lineup--link:nth-child(3), .taxonomy-ling_cat2_lineup--link:nth-child(4) {
    margin: 40px 0 0;
  }
}

.taxonomy-ling_cat2_lineup--img {
  display: block;
  max-width: 100%;
  width: 220px;
  height: 220px;
  object-fit: cover;
  margin: 0 0 10px;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_lineup--img {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
}

.taxonomy-ling_cat2_lineup--text {
  font-size: 14px;
  font-family: "Noto Serif JP", serif;
  line-height: 1.5;
  text-align: center;
  margin: 0 0 12px;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_lineup--text {
    font-size: 11px;
  }
}

.taxonomy-ling_cat2_lineup--price {
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 16px;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_lineup--price {
    font-size: 14px;
  }
}

.taxonomy-ling_cat2_lineup--more {
  font-family: "Noto Serif JP", serif;
  font-size: 12px;
  display: block;
  width: 120px;
  margin: 0 auto;
  padding-bottom: 10px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.taxonomy-ling_cat2_lineup--more:before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(45deg);
  top: calc(50% - 8px);
  right: 0;
}

.taxonomy-ling_cat2_lineup--more:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #000;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_lineup--more {
    font-size: 16px;
  }
}

.taxonomy-ling_cat2_banners {
  background: #F3F3F3;
  padding: 80px 0;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_banners {
    padding: 40px 0;
  }
}

.taxonomy-ling_cat2_banners--inner {
  width: 984px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_banners--inner {
    width: calc(100% - 56px);
  }
}

.taxonomy-ling_cat2_banners--link {
  width: 480px;
  display: block;
  transition: 0.3s;
}

.taxonomy-ling_cat2_banners--link:nth-child(n+3) {
  margin: 24px 0 0;
}

.taxonomy-ling_cat2_banners--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_banners--link {
    width: 100%;
  }
  .taxonomy-ling_cat2_banners--link:nth-child(n+2) {
    margin: 12px 0 0;
  }
}

.taxonomy-ling_cat2_top_section {
  max-width: 720px;
  margin: 0 auto 80px auto;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_top_section {
    padding: 0 20px;
    margin-bottom: 60px;
  }
}

.taxonomy-ling_cat2_top_section--description {
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
}

.taxonomy-ling_cat2_top_section--nav {
  margin: 33px auto 0 auto;
  display: flex;
  justify-content: space-between;
  max-width: 680px;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_top_section--nav {
    flex-direction: column;
  }
}

.taxonomy-ling_cat2_top_section--nav-center {
  justify-content: center;
}

.taxonomy-ling_cat2_top_section--link {
  border: 1px solid #707070;
  max-width: 320px;
  width: 100%;
  font-size: 15px;
  text-decoration: none;
  color: #333;
  text-align: center;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s;
  position: relative;
}

.taxonomy-ling_cat2_top_section--link:after {
  content: '';
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
  transform: rotate(-45deg);
  right: 20px;
  top: calc(50% - 2.5px);
}

.taxonomy-ling_cat2_top_section--link:hover {
  background: #f1f1f1;
}

@media (max-width: 428px) {
  .taxonomy-ling_cat2_top_section--link {
    width: 100%;
  }
  .taxonomy-ling_cat2_top_section--link:last-child {
    margin-top: 12px;
  }
}
